exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-resource-centre-theme-src-pages-404-tsx": () => import("./../../../node_modules/resource-centre-theme/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-resource-centre-theme-src-pages-404-tsx" */),
  "component---node-modules-resource-centre-theme-src-pages-gated-tsx": () => import("./../../../node_modules/resource-centre-theme/src/pages/gated.tsx" /* webpackChunkName: "component---node-modules-resource-centre-theme-src-pages-gated-tsx" */),
  "component---node-modules-resource-centre-theme-src-pages-user-collections-tsx": () => import("./../../../node_modules/resource-centre-theme/src/pages/user_collections.tsx" /* webpackChunkName: "component---node-modules-resource-centre-theme-src-pages-user-collections-tsx" */),
  "component---src-pages-data-dashboard-tsx": () => import("./../../../src/pages/data-dashboard.tsx" /* webpackChunkName: "component---src-pages-data-dashboard-tsx" */),
  "component---src-resource-centre-theme-views-pages-collection-tsx": () => import("./../../../src/resource-centre-theme/views/pages/Collection.tsx" /* webpackChunkName: "component---src-resource-centre-theme-views-pages-collection-tsx" */),
  "component---src-resource-centre-theme-views-pages-document-tsx": () => import("./../../../src/resource-centre-theme/views/pages/Document.tsx" /* webpackChunkName: "component---src-resource-centre-theme-views-pages-document-tsx" */),
  "component---src-resource-centre-theme-views-pages-offline-library-tsx": () => import("./../../../src/resource-centre-theme/views/pages/OfflineLibrary.tsx" /* webpackChunkName: "component---src-resource-centre-theme-views-pages-offline-library-tsx" */),
  "component---src-resource-centre-theme-views-pages-page-tsx": () => import("./../../../src/resource-centre-theme/views/pages/Page.tsx" /* webpackChunkName: "component---src-resource-centre-theme-views-pages-page-tsx" */),
  "component---src-resource-centre-theme-views-pages-pdf-tsx": () => import("./../../../src/resource-centre-theme/views/pages/PDF.tsx" /* webpackChunkName: "component---src-resource-centre-theme-views-pages-pdf-tsx" */),
  "component---src-resource-centre-theme-views-pages-search-tsx": () => import("./../../../src/resource-centre-theme/views/pages/Search.tsx" /* webpackChunkName: "component---src-resource-centre-theme-views-pages-search-tsx" */),
  "component---src-resource-centre-theme-views-pages-term-tsx": () => import("./../../../src/resource-centre-theme/views/pages/Term.tsx" /* webpackChunkName: "component---src-resource-centre-theme-views-pages-term-tsx" */)
}

